<template>
  <v-app-bar>
    <template v-slot:prepend>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon class="hidden-lg-and-up" v-bind="props" />
        </template>
        <v-list>
          <v-list-item link to="/" title="Home"></v-list-item>
          <v-list-item disabled link href="https://app.cemeterycommunity.com" target="_blank" title="Cemetery Login" />
          <v-list-item disabled link href="https://genealogy.cemeterycommunity.com" target="_blank" title="Cemetery Genealogy" />
          <v-list-item link href="https://demo.cemeterycommunity.com" target="_blank" title="Demo" />
          <v-list-item link href="https://www.danstechservices.us" target="_blank" title="Dan's Tech Services" />
        </v-list>

      </v-menu>
    </template>

    <v-app-bar-title>Cemetery Community</v-app-bar-title>

    <v-spacer class="hidden-md-and-down"/>
    <v-btn class="hidden-md-and-down" link to="/" text="Home" />
    <v-btn class="hidden-md-and-down" disabled link href="https://demo.cemeterycommunity.com" target="_blank" text="Cemetery Login" />
    <v-btn class="hidden-md-and-down" disabled link href="https://genealogy.cemeterycommunity.com" target="_blank" text="Cemetery Genealogy" />
    <v-btn class="hidden-md-and-down" link href="https://demo.cemeterycommunity.com" target="_blank" text="Demo" />
    <v-btn class="hidden-md-and-down" link href="https://www.danstechservices.us" target="_blank" text="Dan's Tech Services" />
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Welcome to Cemetery Community!</v-card-title>
          <v-card-text>Cemetery Community is a simple, intuitive, and easy to use software designed by Dan's Tech Services to help digitalize your cemeteries records and access them from any device, anywhere with internet access.</v-card-text>
          <v-card-text>Our mission is to make it easy for cemeteries of any size to have access to basic, modern tools that nearly all companies have been able to utilize.</v-card-text>
          <v-card-text>Quickly pull up information about your customers, find out who is on the same lot as one of your spaces, assign and complete work orders, and record burials or plan for upcoming ones all from one place.</v-card-text>
          <v-card-text>As a subscription based software, you get all updates for free as long as you are a member, no upgrade costs and no hidden fees.</v-card-text>
          <v-card-text>We also offer a discounted rate for cemeteries that are no longer performing burials and only want to have their records kept and accessible digitally</v-card-text>
          <v-card-text>Contact us to learn more about what Cemetery Community software can do for your cemetery by tapping one of the options below, or try our demo!</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn link href="https://demo.cemeterycommunity.com" text="Try our demo"/>
            <v-btn link href="mailto:dan@danstechservices.us" prepend-icon="mdi-email"><v-tooltip activator="parent" location="top">dan@danstechservices.us</v-tooltip></v-btn>
            <v-btn link href="tel:8103283419" prepend-icon="mdi-phone"><v-tooltip activator="parent" location="top">8103283419</v-tooltip></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    
  },
});
</script>